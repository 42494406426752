<template>
  <section
    class="bg-white sm:text-left shadow-xl rounded-xl sm:w-full sm:p-14 sm:px-14 p-5"
  >
    <h3 class="sm:text-3xl font-bold text-lg">
      Tendencia de las Quejas por Fecha
    </h3>
    <apexchart
      class="mt-6"
      heigth="100%"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>
<script>
export default {
  props: {
    labelsx: {
      type: [],
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    bars: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        noData: {
          text: "No hay datos para graficar",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "24px",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          fontSize: "20em",
          itemMargin: {
            horizontal: 20,
            vertical: 0,
          },
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1],
        },
        labels: [],
        xaxis: {
          type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Total Entidades",
            },
          },
          {
            opposite: true,
            title: {
              text: "Total Quejas",
            },
          },
        ],
        responsive: [
          {
            breakpoint: 1024,
            options: {
              chart: {
                // height: "90%",
                // width: "100%",
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 3,
                },
              },
              legend: {
                position: "bottom",
                fontSize: "12em",
              },
              yaxis: [
                {
                  title: {
                    text: "",
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: "",
                  },
                },
              ],
            },
          },
          {
            breakpoint: 600,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 1,
                },
              },
            },
          },
        ],

        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#255aee",
            shadeTo: "dark",
            shadeIntensity: 0.8,
          },
        },
      },
    };
  },
  created() {
    this.chartOptions.labels = this.labelsx;
    this.series.push(this.bars);
    this.series.push(this.line);
    this.chartOptions.plotOptions.borderRadius = this.bars.length > 10 ? 0 : 20;
  },

  updated() {},
};
</script>